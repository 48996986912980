import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  private isChecked = false
  private isDisabled = false

  onChange: (checked: boolean) => void = () => {}
  onTouched: () => void = () => {}

  @Input() nonControlChecked = false

  @Input() isNonControl = false

  @Output() nonControlEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  @ViewChild('checkbox') checkbox!: MatCheckbox

  set checked(value: boolean) {
    if (this.isChecked !== value) {
      this.isChecked = value
      this.onChange(value)
      this.onTouched()
    }
  }

  get checked(): boolean {
    return this.isChecked
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nonControlChecked'] && !changes['nonControlChecked'].firstChange) {
      this.writeValue(this.nonControlChecked)
    }
  }

  ngOnInit(): void {
    if (this.isNonControl) {
      this.writeValue(this.nonControlChecked)
    }
  }

  ngAfterViewInit(): void {
    if (this.checkbox) {
      this.checkbox.disabled = this.isDisabled
    }
  }

  change(event: boolean): void {
    if (!this.isNonControl) {
      return
    }

    this.nonControlEvent.emit(event)
  }

  writeValue(value: boolean): void {
    if (this.checkbox) {
      this.checkbox.checked = value
    }

    this.checked = value
  }

  registerOnChange(fn: (checked: boolean) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled

    if (this.checkbox) {
      this.checkbox.disabled = isDisabled
    }
  }
}
